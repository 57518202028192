import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import { Link, useSearchParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import {
  DressState,
  fetchAllDress,
  fetchAllDressNoPublicDress,
} from "../redux/slices/dresses";
import { IDressData } from "../redux/models/IDress";
import Dresses from "../components/dresses";
import Pagination from "@mui/material/Pagination";
import Search from "../components/search";

export default function Home() {
  const dispatch = useDispatch();

  const { dresses }: any = useSelector((state: DressState) => state.dresses);
  const isDressesLoading = dresses.status === "loading";
  const [currentCategoryTab, setCurrentCategoryTab] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [visablePagination, setVisablePagination] = useState(true);
  const [currentCategory, setCurrentCategory] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const currentTabIndex = (currentCategoryTabName: string) => {
    switch (currentCategoryTabName) {
      case "свадебные платья":
        return setCurrentCategoryTab(1);
      case "вечерние платья":
        return setCurrentCategoryTab(2);
      case "коктейльные платья":
        return setCurrentCategoryTab(3);
      case "костюмы, комбинезоны":
        return setCurrentCategoryTab(4);
      case "платья больших размеров":
        return setCurrentCategoryTab(5);
      case "аксессуары":
        return setCurrentCategoryTab(6);
      default:
        return setCurrentCategoryTab(0);
    }
  };

  useEffect(() => {
    // @ts-ignore
    const params = Object.fromEntries(
      // @ts-ignore
      [...searchParams].map(([key, value]) => [key, decodeURIComponent(value)])
    );

    const { page, published, category, sale } = params;
    setPage(Number(page) || 1);
    if (!published && category !== "все" && !sale) {
      dispatch(fetchAllDress({ ...params }));
    }

    if (category === "все") {
      dispatch(fetchAllDress({ page: page }));
    }
    currentTabIndex(category);

    if (published) {
      setCurrentCategoryTab(7);
      dispatch(fetchAllDressNoPublicDress(params));
    }

    if (sale) {
      setCurrentCategoryTab(8);
      dispatch(fetchAllDressNoPublicDress(params));
    }
  }, [dispatch, searchParams, currentCategory, page, currentCategoryTab]);

  let pag = Math.ceil(dresses.totalPage / 12);
  let hr = pag <= 1 ? 1 : pag;

  const handleChange = (event: any, value: string) => {
    setPage(1);
    if (event.target.innerText === "ВСЕ") {
      setCurrentCategory("");
      setVisablePagination(true);
    }

    if (event.target.innerText.toLowerCase().trim() === "скрытые") {
      setCurrentCategory("");
      setSearchParams({ published: "false", limit: "12" });
      return dispatch(
        fetchAllDressNoPublicDress({ published: "false", limit: "12" })
      );
    }
    if (event.target.innerText.toLowerCase().trim() === "sale") {
      setCurrentCategory("");
      setSearchParams({ sale: "true", limit: "12" });
      return dispatch(
        fetchAllDressNoPublicDress({ sale: "true", limit: "12" })
      );
    }
    setCurrentCategory(event.target.innerText);
    setVisablePagination(true);
    return setSearchParams({
      category: event.target.innerText.toLowerCase().replace("%0A", "").trim(),
    });
  };

  const handleChangePagination = (event: any, value: any) => {
    // @ts-ignore
    const params = Object.fromEntries([...searchParams]);
    const { published, sale } = params;
    setPage(value);
    if (published) {
      return setSearchParams({
        page: value,
        published: "false",
        limit: "12",
      });
    }

    if (sale) {
      return setSearchParams({
        page: value,
        sale: "true",
        limit: "12",
      });
    }
    return setSearchParams({
      page: value,
      category: currentCategory.toLowerCase().trim(),
    });
  };

  if (isDressesLoading) {
    return <span>Загрузка...</span>;
  }

  return (
    <>
      <Box display="flex" sx={{ flexDirection: { xs: "column", md: "row" } }}>
      <Tabs
        style={{ marginBottom: 35 }}
        value={currentCategoryTab}
        aria-label="basic tabs example"
        onChange={handleChange}
        variant="scrollable"
        allowScrollButtonsMobile
      >
        <Tab label="Все" />
        <Tab label="Свадебные платья" />
        <Tab label="Вечерние платья" />
        <Tab label="Коктейльные платья" />
        <Tab label="Костюмы, комбинезоны" />
        <Tab label="Платья больших размеров" />
        <Tab label="Аксессуары" />
        <Tab label="Скрытые" />
        <Tab label="sale" />
      </Tabs>
      </Box>
      <Box display="flex" sx={{ flexDirection: { xs: "column", md: "row" } }}>
        <Button
          component={Link}
          color="secondary"
          to="/add-dress"
          variant="contained"
          size="large"
          sx={{ mb: 2 }}
        >
          Добавить платишко
        </Button>
        <Search />
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ display: { xs: "none", sm: "table-cell" } }}>
                Фото
              </TableCell>
              <TableCell align="left">Название</TableCell>
              <TableCell align="left">Категория</TableCell>
              <TableCell align="left">Цена на мероприятие</TableCell>
              <TableCell align="left">Цена на покупку</TableCell>
              <TableCell align="left">Сортировка</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dresses?.items.map((item: IDressData) => (
              <Dresses key={item.id} data={item} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {hr > 1 && visablePagination && visablePagination && (
        <Stack spacing={2}>
          <div style={{ marginTop: "20px" }}>
            <Pagination
              onChange={handleChangePagination}
              count={hr}
              page={page || 1}
            />
          </div>
        </Stack>
      )}
    </>
  );
}
